<template>
	<b-modal size="xxl" ref="modalSignature" @hidden="resetModal" @show="init_component">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.valider') }}</h2>
		</template>
		<template slot="default">

			<div class="row">
				<div class="col-6">
					<div class="form-group">
						<label for="">{{ $t('monte.validation_date') }}</label>
						<e-datepicker v-model="validation_date" :class="{ 'is-invalid': signatureError && signatureError.indexOf('validation_date') > -1 }"></e-datepicker>
					</div>
				</div>

				<div class="col-6">
					<div class="form-group">
						<label for="">{{ $t('monte.signature_date') }}</label>
						<e-datepicker v-model="signature_date" :class="{ 'is-invalid': signatureError && signatureError.indexOf('signature_date') > -1 }"></e-datepicker>
					</div>
				</div>
			</div>

			<div class="form-group" v-if="syndic_saillie && syndic_saillie.length > 0">
				<label for="type_contrat">{{ $t('monte.type_contrat') }} *</label>
				<e-select
					v-model="type_contrat"
					id="type_contrat"
					track-by=""
					:placeholder="$t('monte.type_contrat')"
					:selectedLabel="$t('global.selected_label')"
					:options="list_type_contrat"
					:allow-empty="false"
					:show-labels="false"
					:class="{ 'is-invalid': signatureError && signatureError.indexOf('type_contrat') > -1 }"
				>
                    <template slot="option" slot-scope="{ option }">{{ $t('monte.'+option) }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ $t('monte.'+option) }}</template>
				</e-select>
			</div>

			<div class="form-group" v-if="type_contrat == 'sur_parts' || type_contrat == 'sur_pool'">
				<label for="saillie">{{ $t('monte.numero_saillie') }}</label>
	            <e-select
					v-model="saillie"
					id="saillie"
					track-by=""
					:placeholder="$t('monte.numero_saillie')"
					:selectedLabel="$t('global.selected_label')"
					:options="syndic_saillie_formatted"
					:allow-empty="false"
					:show-labels="false"
					:loading="load_syndic"
					:class="{ 'is-invalid': signatureError && signatureError.indexOf('saillie') > -1 }"
				>
				 	<template slot="option" slot-scope="{ option }">{{ option.value }}</template>
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.value }}</template>
				</e-select>
				<b-form-checkbox
					v-if="saillie"
					id="free_saillie"
					v-model="free_saillie"
					:value="1"
				>
					{{ $t("monte.free_saillie") }}
				</b-form-checkbox>
	        </div>

			<div v-if="show_resa">
				<b-form-checkbox
					class="custom-control custom-checkbox"
					v-model="add_resa" 
				>
				{{ $t('monte.ajout_ligne_resa') }} ({{ form.paillette }} {{ $t('monte.paillettes') }})
				</b-form-checkbox>
			</div>

		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="checkForm()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('monte.valider') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Syndic from "@/mixins/Syndic.js"
	import Contract from "@/mixins/Contract.js"
	import _isEmpty from 'lodash/isEmpty'
	import Accounting from "@/mixins/Accounting.js"
    import AccountingAccount from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	import ReservationMixin from "@/mixins/MonteReservationOrder.js"
	
	export default {
		name: 'ModalSignature',
		mixins: [Syndic, Contract, Accounting, AccountingAccount, ArticleMixin, ReservationMixin],
		props: {
			contract_id:{
                type: Number,
                default: () => ( 0 )
            },
			avenant: {
				type: Object,
				default: () => ( {} )
			},
			tiers_tab: {
				type: Array,
				default: () => ( [] )
			},
			tiers: {
				type: Object,
				default: () => ( null )
			},
			type_monte:{
				type: Object,
				default: () => ( null )
			},
			contractconfig_id:{
				type: Number,
				default: () => ( 0 )
			},
			stallion: {
				type: Object,
				default: () => ( {} )
			},
			contract_config: {
				type: Object,
				default: () => ( {} )
			},
			season_id:{
                type: Number,
                default: () => ( 0 )
            },
			syndic_saillie: {
				type: Array,
				default: () => ( [] )
			},
			load_syndic: {
				type: Boolean,
				default: () => ( false )
			},
			num_dps: {
				type: String,
				default: () => ( null )
			},
			form: {
				type: Object,
				default: () => ( {} )
			}
		},
		data () {
			return {
				signatureError: [],
				validation_date: new Date(),
				signature_date: null,
				type_contrat: null,
				saillie: null,
				numero_saillie: null,
				processing: false,
				list_type_contrat : [],
				avenant_article: {},
				// syndic_saillie: [],
				errors: [],
				add_resa: false,
				saillie_loading: false,
				free_saillie: 0
			}
		},

		methods: {
			async init_component() {
				this.setListTypeMonte()
				if(!this.signature_date) { 
					this.signature_date = new Date();
				}
				if(this.avenant.avenant_date_validation) {
					this.validation_date = new Date(this.avenant.avenant_date_validation)
				}
				if(this.avenant.avenant_type_contrat) {
					this.type_contrat = this.avenant.avenant_type_contrat
				}
			},
			openModal() {
				this.$refs.modalSignature.show()
			},
			hideModal() {
				this.$refs.modalSignature.hide()
			},
			async checkForm() {
				this.processing = true

				this.signatureError = []

				if(this.type_contrat == null)
				{
					this.signatureError.push("type_contrat")
				}

				if(this.signatureError.length > 0){
					this.processing = false
					return false
				}

				await this.$sync.runOptionalWhishlist(['contract'])

				let contract_id = this.$sync.replaceWithReplicated('contract', this.contract_id)

				let status = 2
				if(this.signature_date != null)
				{
					status = 3
				}

				let params = {
					signature_date: this.signature_date,
					validation_date: this.validation_date,
					type_contrat: this.type_contrat,
					avenant_dps: this.num_dps,
					status: status,
					porteuse: (this.form.porteuse && this.form.porteuse.horse_id) ? this.form.porteuse : null,
					cmep: this.form.cmep
				}
				
				let res = await this.saveContractOnline(contract_id, params, this.contractconfig_id, this.avenant.avenant_id, this.avenant_article, true, true)

				if(this.saillie != null && (this.type_contrat == "sur_parts" || this.type_contrat == "sur_pool")){
					await this.savePartContract(contract_id, this.saillie.saillie_id)
				}
				else if(!this.saillie && this.free_saillie) {
					await this.freeSaillieContractv2(contract_id)
				}

				if(this.add_resa && window.navigator.onLine) {
					const params = {
						destinataire: this.avenant.cmep.tiers_id,
						client:  this.form.tiers.tiers_id,
						mare: this.form.jument.horse_id,
						stallion: 	this.stallion.horse_id,
						doses: 0,
						paillettes: this.form.paillette,
						comment: '',
						delivery_date: null
					}
					await this.addContractSailliesReservations(contract_id, params)
				}

				// await this.$sync.force(true, true)

				this.processing = false
				this.$emit("contract_signed", true)
			},
			resetModal() {
				this.signatureError = [];
				this.validation_date = new Date();
				this.signature_date = null;
				this.type_contrat = null;
				this.saillie = null;
				this.numero_saillie = null;
				this.processing = false;
				this.list_type_contrat  = [];
				this.avenant_article = {};
				// this.syndic_saillie = [];
				this.errors = []
			},
			setListTypeMonte() {
				this.list_type_contrat = ['sur_parts', 'sur_pool', 'etalonnier']
				this.type_contrat = 'etalonnier'

				if(!_isEmpty(this.stallion)) {
					// this.saillie_loading = true
					// this.syndic_saillie = await this.loadHorseSyndicBySeason(this.stallion.horse_id, this.season_id)
					// this.saillie_loading = false
					if(!this.syndic_saillie) {
						this.list_type_contrat = ['etalonnier']
						this.type_contrat = this.list_type_contrat[0]
					}
				}
			}
		},
		computed: {
			syndic_saillie_formatted() {
				if(_isEmpty(this.syndic_saillie)) return []

				let type_contrat = "syndicpart_is_perso"
				if(this.type_contrat == "sur_pool")
				{
					type_contrat = "syndicpart_is_pool"
				}

				let res = []
				this.syndic_saillie.forEach(syndic => {
					syndic.syndic_saillies.forEach(saillie => {
						if(saillie.saillie_part[type_contrat] == 1) {
							if(saillie.saillie_part.syndicpart_tiers.length === 0 || (saillie.saillie_contract !== null && saillie.saillie_contract !== this.contract_id)) {
								return
							}
							
							let tiers_tab = []
							saillie.saillie_part.syndicpart_tiers.forEach(tiers => {
								tiers_tab.push(tiers.tierpart_tier.tiers_rs)
							})

							const tab_current = {
								value: `${syndic.syndic_label} # ${saillie.saillie_number} / ${tiers_tab.join(' - ')} / ${this.$t(saillie.saillie_part.syndicpart_invoice_type.invoicetype_labeltrad)}`,
								invoice_type: saillie.saillie_part.syndicpart_invoice_type.invoicetype_code,
								saillie_id: saillie.saillie_id,
								type_contrat: type_contrat,
								syndicat: this.syndic_saillie.syndic_tiersyndicat,
								tiers: {
										tiers_id:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_id,
										tiers_rs:saillie.saillie_part.syndicpart_tiers[0].tierpart_tier.tiers_rs
									}
							}

							res.push(tab_current)

							if(saillie.saillie_contract == this.contract_id) {
								this.has_part = true
								this.saillie = tab_current
							}
						}
					})
				})
				return res
			},
			show_resa() {
				return this.avenant.cmep && this.type_monte && this.type_monte.type == 'iac' && this.contract_config && window.navigator.onLine
			}
		},
		watch:{
			type_contrat(val){
				this.saillie = null
			},
			syndic_saillie(val) {
				this.setListTypeMonte()
			}
		}
	}

</script>